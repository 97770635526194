@use 'base/colors' as *;

::ng-deep .toast-container .ngx-toastr {
  box-shadow: 0 5px 10px rgba($cos-blackish, 0.1);
  padding: 8px 24px 0 8px;
  width: 480px;
  border-radius: 5px;

  &:hover {
    box-shadow: 0 5px 10px rgba($cos-blackish, 0.1);
  }
}
