@keyframes bidIncreased {
  0% {
    transform: scale(1);
  }

  35% {
    transform: scale(1.15);
    filter: brightness(115%);
  }

  100% {
    transform: scale(1);
  }
}
