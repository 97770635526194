@use 'base/colors' as *;

@keyframes highlightBidIncreased {
  0% {
    transform: scale(1);
  }

  20% {
    color: $cos-purple;
  }

  35% {
    transform: scale(1.15);
  }

  70% {
    color: $cos-purple;
  }

  100% {
    transform: scale(1);
  }
}
