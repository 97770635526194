$cos-yellow-dark: #ebaa00;
$cos-yellow: #fcc024;
$cos-yellow-highlight: #fcd15e;
$cos-yellow-light: #fde29b;
$cos-yellow-ultralight: #fff2d1;

$cos-purple-dark: #2b0a5c;
$cos-purple: #5514b4;
$cos-purple-highlight: #934cff;
$cos-purple-light: #e7dafb;
$cos-purple-ultralight: #f4edff;

$cos-green-dark: #1f711e;
$cos-green: #1eac1b;
$cos-green-highlight: #4dcf4a;
$cos-green-light: #90e18e;
$cos-green-ultralight: #ddf8dd;

$cos-orange-dark: #d35c06;
$cos-orange: #eb7911;
$cos-orange-highlight: #ff9533;
$cos-orange-light: #ffbf85;
$cos-orange-ultralight: #ffebd9;

$cos-red-dark: #9a1d1f;
$cos-red: #cd1e14;
$cos-red-highlight: #ff1a1d;
$cos-red-light: #ff9ea0;
$cos-red-ultralight: #fef0f0;

$cos-black: #000000;
$cos-blackish: #262626;
$cos-black-light: #404040;
$cos-grey-ultradark: #595959;
$cos-grey-dark: #808080;
$cos-grey: #b3b3b3;
$cos-grey-light: #e6e6e6;
$cos-grey-ultralight: #f3f3f3;
$cos-white: #ffffff;

:root {
  --cos-yellow-dark: #{$cos-yellow-dark};
  --cos-yellow: #{$cos-yellow};
  --cos-yellow-highlight: #{$cos-yellow-highlight};
  --cos-yellow-light: #{$cos-yellow-light};
  --cos-yellow-ultralight: #{$cos-yellow-ultralight};

  --cos-purple-dark: #{$cos-purple-dark};
  --cos-purple: #{$cos-purple};
  --cos-purple-highlight: #{$cos-purple-highlight};
  --cos-purple-light: #{$cos-purple-light};
  --cos-purple-ultralight: #{$cos-purple-ultralight};

  --cos-green-dark: #{$cos-green-dark};
  --cos-green: #{$cos-green};
  --cos-green-highlight: #{$cos-green-highlight};
  --cos-green-light: #{$cos-green-light};
  --cos-green-ultralight: #{$cos-green-ultralight};

  --cos-orange-dark: #{$cos-orange-dark};
  --cos-orange: #{$cos-orange};
  --cos-orange-highlight: #{$cos-orange-highlight};
  --cos-orange-light: #{$cos-orange-light};
  --cos-orange-ultralight: #{$cos-orange-ultralight};

  --cos-red-dark: #{$cos-red-dark};
  --cos-red: #{$cos-red};
  --cos-red-highlight: #{$cos-red-highlight};
  --cos-red-light: #{$cos-red-light};
  --cos-red-ultralight: #{$cos-red-ultralight};

  --cos-black: #{$cos-black};
  --cos-blackish: #{$cos-blackish};
  --cos-black-light: #{$cos-black-light};
  --cos-grey-ultradark: #{$cos-grey-ultradark};
  --cos-grey-dark: #{$cos-grey-dark};
  --cos-grey: #{$cos-grey};
  --cos-grey-light: #{$cos-grey-light};
  --cos-grey-ultralight: #{$cos-grey-ultralight};
  --cos-white: #{$cos-white};
}
