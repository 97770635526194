/**
  Animation tokens

  Usage:
  ```
  @use 'base/animations/tokens' as *;

  .element {
    transition: $background-color, $outline, $box-shadow, $transform;
  }
  ```
*/

$transform: transform 50ms ease-in;
$slow-transform: transform 250ms ease-in;
$filter: filter 200ms ease-out;
$color: color 200ms linear;
$background-color: background-color 200ms linear;
$border-color: border-color 200ms ease-in;
$outline: outline 200ms linear;
$box-shadow: box-shadow 200ms linear;
$opacity: opacity 200ms ease-in;
$visibility: visibility 200ms ease-in;
$max-height: max-height 250ms ease-in-out;
