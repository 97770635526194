@use 'base/colors' as *;

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $cos-black;
  font-weight: bold;
}

// avoid the yellow cursor everywhere
*[class^='mat-'],
*[class^='mdc-'],
*[class*=' mat-'],
*[class*=' mdc-'] {
  caret-color: auto !important;
}
