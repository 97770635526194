@use '@angular/material' as mat;

// TODO: find out why the sass-loader does not accept '@angular/material/core/typography/typography'
@use '../../../../../node_modules/@angular/material/core/typography/typography' as matTypography;
@use 'base/colors' as *;
@import '@angular/material/theming';
@import 'animate.css/animate.min.css';
@import 'base/mixins';

// Include the @cos styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core;
@include mat.legacy-core;

$mat-light: (
  50: #fafafa,
  100: #f9f9f9,
  200: #f8f8f8,
  300: #f8f8f8,
  400: #f8f8f8,
  500: #f8f8f8,
  600: #f8f8f8,
  700: #f8f8f8,
  800: #f8f8f8,
  900: #f8f8f8,
  A100: #f8f8f8,
  A200: #f8f8f8,
  A400: #f8f8f8,
  A700: #f8f8f8,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  ),
);
$mat-green: (
  50: #e8f5e9,
  100: #c8e6c9,
  200: #a5d6a7,
  300: #81c784,
  400: #66bb6a,
  500: #4caf50,
  600: #43a047,
  700: #388e3c,
  800: #2e7d32,
  900: #1b5e20,
  A100: #b9f6ca,
  A200: #69f0ae,
  A400: #00e676,
  A700: #00c853,
  contrast: (
    50: $light-primary-text,
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $light-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);
$mat-green: (
  50: #e8f5e9,
  100: $cos-green-ultralight,
  200: #a5d6a7,
  300: #81c784,
  400: #66bb6a,
  500: $cos-green-light,
  600: #43a047,
  700: $cos-green-dark,
  800: #2e7d32,
  900: #1b5e20,
  A100: #b9f6ca,
  A200: #69f0ae,
  A400: #00e676,
  A700: #00c853,
  contrast: (
    50: $light-primary-text,
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $light-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);
$mat-red: (
  50: #ffebee,
  100: $cos-red-light,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: $cos-red,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);
$mat-amber: (
  50: $cos-yellow-ultralight,
  100: #ffecb3,
  200: #ffe082,
  300: #ffd54f,
  400: $cos-yellow-highlight,
  500: $cos-yellow,
  600: #ffb300,
  700: #ffa000,
  800: #ff8f00,
  900: #ff6f00,
  A100: #ffe57f,
  A200: #ffd740,
  A400: #ffc400,
  A700: #ffab00,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$cos-app-primary: mat.define-palette($mat-amber);
$cos-app-accent: mat.define-palette($mat-light);
$cos-app-success: mat.define-palette($mat-green);
$cos-app-warn: mat.define-palette($mat-red);
$cos-app-light: mat.define-palette($mat-light);

// Create the theme object (a Sass map containing all of the palettes).
$cos-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $cos-app-primary,
      accent: $cos-app-accent,
      warn: $cos-app-warn,
    ),
    // the subheading-1 is taken as "body-1" in node_modules/@angular/material/core/typography/_typography.scss
    typography:
      matTypography.define-legacy-typography-config(
        $subheading-1: matTypography.define-typography-level(14px, 20px, 400),
      ),
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($cos-app-theme);
@include mat.all-legacy-component-typographies;
@include mat.all-legacy-component-themes($cos-app-theme);

.mat-button-toggle-checked {
  background-color: $cos-yellow-highlight;
  font-weight: bold;
}

body {
  margin: 0;
}

.cos-body {
  background-color: $cos-grey-ultralight;
}

// Remove all dotted lines for buttons and links
a,
a:active,
a:focus,
button,
button:focus,
button:active,
.btn,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn.active.focus {
  outline: none;
  outline: 0;
}

a {
  color: black;
  text-decoration: none; /* no underline */
}

.view-title {
  text-transform: uppercase;
  letter-spacing: 2px !important;
  line-height: 1.2em;
}

.view-subtitle {
  font-size: 16px;
  letter-spacing: 1px;
  color: $cos-grey;
  text-transform: uppercase;
}

.view-hint-centered {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 15%;
  color: $cos-grey;
}

.toolbar-label {
  margin-left: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.row-spacer {
  flex: 1 1 auto;
}

.text-accent {
  color: $cos-grey;
}

.primary {
  color: $cos-yellow-highlight;
}

.text-success {
  color: $cos-green;
}

.text-warn {
  color: $cos-red;
}

.text-grey {
  color: $cos-grey;
}

.text-small {
  font-size: 12px;
}

.text-icon {
  margin-left: 5px;
  margin-right: 5px;
}

// from bootstrap
.text-right {
  text-align: right !important;
}

.busy-item-parent {
  position: relative;
}

.busy-item {
  position: absolute;
  z-index: 100;
  opacity: 0.7;
  background-color: $cos-white;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.full-width {
  width: 100%;
}

.text-center {
  display: inline-block;
  text-align: center;
}

.auction-thumbnail {
  width: 100%;
  max-height: 120px;
  height: 120px;
  object-fit: cover;
}

.scrollable-dialog-container {
  overflow: hidden scroll;

  mat-dialog-container {
    overflow: visible; // enable 'position: sticky' (in matDialogs)
  }
}

.text-normal {
  font-size: 14px;
  line-height: 20px;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

.preload-image {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.cos-success {
  background-color: $cos-green;
  color: $cos-grey-ultralight;
}

.cos-fail {
  background-color: $cos-red;
  color: $cos-grey-ultralight;
}

.cos-warning {
  background-color: $cos-orange;
  color: $cos-grey-ultralight;
}

.center-element {
  margin: 0 auto;
}

.pull-right {
  float: right;
}

.card-warn {
  background-color: $cos-red;
  color: $cos-grey-ultralight;
}

.card-success {
  background-color: $cos-green;
  color: $cos-grey-ultralight;
}

// buyer - auction - detail dialog
.buyer-auction-detail-dialog {
  width: 100%;

  .mat-dialog-container {
    padding: 0 8px;
    overflow-x: hidden;
  }

  @include breakpoint('md') {
    width: 95%;
    max-width: 1440px !important;

    .mat-dialog-container {
      padding: 0 24px;
    }
  }
}

// salesman - fee - dialog
.salesman-fee-dialog {
  @media screen and (max-width: 960px) {
    width: 90% !important;
    height: 90% !important;
  }
}

// buyer - confirm - auction - dialog
.buyer-confirm-auction-dialog {
  width: 100% !important;
  max-width: 640px !important;

  .mat-dialog-container {
    height: auto;
    max-height: fit-content;
  }
}

// global ellipsis class
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// Survey dialogs
.survey-dialog .mat-dialog-container {
  background-color: map-get($cos-app-primary, 300);
  padding-bottom: 0;

  mat-dialog-content {
    background-color: map-get($mat-light, 50);
  }
}

.x-hellosign-embedded.x-hellosign-embedded--in-modal {
  z-index: 1000;
}

.select-box-image {
  height: 25px;
  margin-right: 5px;
}

.deleted-item {
  text-decoration: line-through;
}

.horizontal-divider {
  margin-top: 5px;
  margin-bottom: 5px;
}

.margin-top-10px {
  margin-top: 10px;
}

.margin-bottom-10px {
  margin-bottom: 10px;
}

.margin-left-10px {
  margin-left: 10px;
}

.margin-right-10px {
  margin-right: 10px;
}

// cookie consent
// not visible for mobile version (implemented in menu)
.cc-revoke.cc-bottom {
  display: block;
  transform: rotate(90deg) translateY(7.25em) translateX(-6em);

  @include breakpoint('sm') {
    transform: translateY(2em);
  }
}

.check-bullets {
  ul {
    padding-left: 5px;
  }

  li {
    list-style: none;
    padding: 2px 0 0 23px;
    background: url('/assets/logo/cos-guarantee/check.svg') no-repeat left;
  }
}

.pswp__custom-caption {
  color: $cos-white;
  border-radius: 5px;
  border: 1px solid $cos-yellow-highlight;
  background: rgba($cos-blackish, 0.75);
  padding: var(--enzo-spacer-1) var(--enzo-spacer-3);
  position: absolute;
  left: 50%;
  bottom: var(--enzo-spacer-5);
  transform: translateX(-50%);

  &:empty {
    display: none;
  }
}

.pswp__pdf-object {
  width: 80%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
