@use 'base/colors' as *;

.mat-datepicker-content {
  background-color: white;
  color: rgba($cos-black, 0.87);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba($cos-black, 0.54);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba($cos-black, 0.54);
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba($cos-black, 0.87);
  border-color: transparent;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected, .mat-calendar-body-comparison-identical) {
  border-color: rgba($cos-black, 0.38);
}

.mat-calendar-body-selected {
  background-color: #ffd452;
  color: rgba($cos-black, 0.87);
}
