@use 'base/colors' as *;

// special breakpoints of login and signup pages
$tablet-breakpoint: 1024px;
$mobile-breakpoint: 480px;

// Breakpoints of flex-layout
// see https://github.com/angular/flex-layout/blob/master/docs/documentation/Responsive-API.md
$media-direction: min; // min || max

$breakpoints: (
  'default': '',
  'xs': 320px,
  'sm': 600px,
  'md': 960px,
  'lg': 1280px,
  'xl': 1920px,
);
$breakpoints-less-than: (
  'lt-sm': 599.9px,
  'lt-md': 959.9px,
  'lt-lg': 1279.9px,
  'lt-xl': 1919.9px,
);
$breakpoints-exact-range: (
  'xs-only': (
    begin: 0,
    end: 599.9px,
  ),
  'sm-only': (
    begin: 600px,
    end: 959.9px,
  ),
  'md-only': (
    begin: 960px,
    end: 1279.9px,
  ),
  'lg-only': (
    begin: 1280px,
    end: 1919.9px,
  ),
  'xl-only': (
    begin: 1920px,
    end: 4999.99px,
  ),
);
$breakpoints-inverted: (
  'not-xs': (
    begin: 0,
    end: 600px,
  ),
  'not-sm': (
    begin: 599.9px,
    end: 960px,
  ),
  'not-md': (
    begin: 959.9px,
    end: 1280px,
  ),
  'not-lg': (
    begin: 1279.9px,
    end: 1920px,
  ),
  'not-xl': (
    begin: 1919.9px,
    end: 5000px,
  ),
);

@mixin breakpoint($breakpoint, $direction: $media-direction) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (#{$direction}-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }

  @if map-has-key($breakpoints-less-than, $breakpoint) {
    $upTo: map-get($breakpoints-less-than, $breakpoint);

    @media (max-width: $upTo) {
      @content;
    }
  }

  @if map-has-key($breakpoints-exact-range, $breakpoint) {
    $rangeStart: map-get(map-get($breakpoints-exact-range, $breakpoint), begin);
    $rangeEnd: map-get(map-get($breakpoints-exact-range, $breakpoint), end);

    @media (min-width: $rangeStart) and (max-width: $rangeEnd) {
      @content;
    }
  }

  @if map-has-key($breakpoints-inverted, $breakpoint) {
    $upTo: map-get(map-get($breakpoints-inverted, $breakpoint), begin);
    $from: map-get(map-get($breakpoints-inverted, $breakpoint), end);

    @media (max-width: $upTo) {
      @content;
    }

    @media (min-width: $from) {
      @content;
    }
  }
}

@mixin dashed-border {
  position: relative;
  transform-style: preserve-3d;
  background-color: $cos-white;

  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    transform: translateZ(-1px);
    border-radius: 5px;
    background-size: 24px 24px;
  }

  &::before {
    top: -1px;
    height: calc(100% + 2px);
    width: 100%;
    background-image: linear-gradient(to right, #888 66%, transparent 34%);
  }

  &::after {
    top: 0;
    left: -1px;
    height: 100%;
    width: calc(100% + 2px);
    background-image: linear-gradient(to bottom, #888 66%, transparent 34%);
  }
}
