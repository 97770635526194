// do what _reboot.scss from bootstrap does (in case we ever want to get rid of bootstrap)
*,
::after,
::before {
  box-sizing: border-box;
}

// _reboot.scss
html {
  text-align: left;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

img {
  vertical-align: middle;
  border-style: none; // Remove the border on images inside links in IE 10-.
}

input,
button,
select,
optgroup,
textarea {
  margin: 0; // Remove the margin in Firefox and Safari
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

[hidden] {
  display: none !important;
}

.badge {
  display: inline-block;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }
}
