@font-face {
  font-family: cos-vehicle;
  src: url('/assets/fonts/cos-vehicle/cos-vehicle.eot?p1skd');
  src:
    url('/assets/fonts/cos-vehicle/cos-vehicle.eot?p1skd#iefix') format('embedded-opentype'),
    url('/assets/fonts/cos-vehicle/cos-vehicle.ttf?p1skd') format('truetype'),
    url('/assets/fonts/cos-vehicle/cos-vehicle.woff?p1skd') format('woff'),
    url('/assets/fonts/cos-vehicle/cos-vehicle.svg?p1skd#cos-vehicle') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: cos-vehicle !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-WD::before {
  content: '\e900';
}

.icon-1702::before {
  content: '\e901';
}

.icon-2600::before {
  content: '\e902';
}

.icon-3306::before {
  content: '\e903';
}

.icon-3342::before {
  content: '\e904';
}

.icon-4706::before {
  content: '\e905';
}

.icon-4806::before {
  content: '\e906';
}

.icon-4807::before {
  content: '\e907';
}

.icon-5501::before {
  content: '\e908';
}

.icon-11411::before {
  content: '\e909';
}

.icon-15596::before {
  content: '\e90a';
}

.icon-15806::before {
  content: '\e90b';
}

.icon-15807::before {
  content: '\e90c';
}

.icon-16214::before {
  content: '\e90d';
}

.icon-18504::before {
  content: '\e90e';
}

.icon-19102::before {
  content: '\e90f';
}

.icon-19631::before {
  content: '\e910';
}

.icon-20322::before {
  content: '\e911';
}

.icon-20605::before {
  content: '\e912';
}

.icon-20700::before {
  content: '\e913';
}

.icon-20908::before {
  content: '\e914';
}

.icon-24050::before {
  content: '\e915';
}

.icon-24607::before {
  content: '\e916';
}

.icon-24804::before {
  content: '\e917';
}

.icon-26280::before {
  content: '\e918';
}

.icon-26281::before {
  content: '\e919';
}

.icon-26282::before {
  content: '\e91a';
}

.icon-26283::before {
  content: '\e91b';
}

.icon-26300::before {
  content: '\e91c';
}

.icon-27804::before {
  content: '\e91d';
}

.icon-28402::before {
  content: '\e91e';
}

.icon-31008::before {
  content: '\e91f';
}

.icon-31028::before {
  content: '\e920';
}

.icon-31302::before {
  content: '\e921';
}

.icon-32705::before {
  content: '\e922';
}

.icon-33303::before {
  content: '\e923';
}

.icon-37901::before {
  content: '\e924';
}

.icon-39205::before {
  content: '\e925';
}

.icon-39402::before {
  content: '\e926';
}

.icon-40608::before {
  content: '\e927';
}

.icon-69107::before {
  content: '\e928';
}

.icon-77110::before {
  content: '\e929';
}

.icon-ABRG::before {
  content: '\e92a';
}

.icon-ABS::before {
  content: '\e92b';
}

.icon-ACHS::before {
  content: '\e92c';
}

.icon-AG::before {
  content: '\e92d';
}

.icon-AG1::before {
  content: '\e92e';
}

.icon-AG2::before {
  content: '\e92f';
}

.icon-AG3::before {
  content: '\e930';
}

.icon-AG4::before {
  content: '\e931';
}

.icon-AHK::before {
  content: '\e932';
}

.icon-AHK2::before {
  content: '\e933';
}

.icon-AIR::before {
  content: '\e934';
}

.icon-AIR1::before {
  content: '\e935';
}

.icon-AIR2::before {
  content: '\e936';
}

.icon-AIR3::before {
  content: '\e937';
}

.icon-AIR4::before {
  content: '\e938';
}

.icon-AIR5::before {
  content: '\e939';
}

.icon-AIR6::before {
  content: '\e93a';
}

.icon-AKLI::before {
  content: '\e93b';
}

.icon-ALAR::before {
  content: '\e93c';
}

.icon-ALU::before {
  content: '\e93d';
}

.icon-ASC::before {
  content: '\e93e';
}

.icon-ASC1::before {
  content: '\e93f';
}

.icon-ASD::before {
  content: '\e940';
}

.icon-ASR::before {
  content: '\e941';
}

.icon-ASTW::before {
  content: '\e942';
}

.icon-AUFL::before {
  content: '\e943';
}

.icon-AUSP::before {
  content: '\e944';
}

.icon-BEL::before {
  content: '\e945';
}

.icon-BREK::before {
  content: '\e946';
}

.icon-CD::before {
  content: '\e947';
}

.icon-CDW::before {
  content: '\e948';
}

.icon-COMP::before {
  content: '\e949';
}

.icon-DA32::before {
  content: '\e94a';
}

.icon-DARE::before {
  content: '\e94b';
}

.icon-DOPV::before {
  content: '\e94c';
}

.icon-DPF::before {
  content: '\e94d';
}

.icon-DULA::before {
  content: '\e94e';
}

.icon-EHVB::before {
  content: '\e94f';
}

.icon-ESP::before {
  content: '\e950';
}

.icon-FA01::before {
  content: '\e951';
}

.icon-FA02::before {
  content: '\e952';
}

.icon-FA03::before {
  content: '\e953';
}

.icon-FA05::before {
  content: '\e954';
}

.icon-FA10::before {
  content: '\e955';
}

.icon-FA15::before {
  content: '\e956';
}

.icon-FA16::before {
  content: '\e957';
}

.icon-FA20::before {
  content: '\e958';
}

.icon-FA21::before {
  content: '\e959';
}

.icon-FA22::before {
  content: '\e95a';
}

.icon-FA23::before {
  content: '\e95b';
}

.icon-FA24::before {
  content: '\e95c';
}

.icon-FA25::before {
  content: '\e95d';
}

.icon-FA26::before {
  content: '\e95e';
}

.icon-FA27::before {
  content: '\e95f';
}

.icon-FA28::before {
  content: '\e960';
}

.icon-FA30::before {
  content: '\e961';
}

.icon-FA31::before {
  content: '\e962';
}

.icon-FA33::before {
  content: '\e963';
}

.icon-FA40::before {
  content: '\e964';
}

.icon-FA41::before {
  content: '\e965';
}

.icon-FA44::before {
  content: '\e966';
}

.icon-FA45::before {
  content: '\e967';
}

.icon-FA46::before {
  content: '\e968';
}

.icon-FA47::before {
  content: '\e969';
}

.icon-FA48::before {
  content: '\e96a';
}

.icon-FA49::before {
  content: '\e96b';
}

.icon-FA50::before {
  content: '\e96c';
}

.icon-FAHR::before {
  content: '\e96d';
}

.icon-FARS::before {
  content: '\e96e';
}

.icon-FEBB::before {
  content: '\e96f';
}

.icon-FEBL::before {
  content: '\e970';
}

.icon-FELL::before {
  content: '\e971';
}

.icon-FH2::before {
  content: '\e972';
}

.icon-FH4::before {
  content: '\e973';
}

.icon-FHSF::before {
  content: '\e974';
}

.icon-FHSN::before {
  content: '\e975';
}

.icon-FROA::before {
  content: '\e976';
}

.icon-FSHB::before {
  content: '\e977';
}

.icon-FSPA::before {
  content: '\e978';
}

.icon-GPS::before {
  content: '\e979';
}

.icon-GUST::before {
  content: '\e97a';
}

.icon-HBLR::before {
  content: '\e97b';
}

.icon-HIAN::before {
  content: '\e97c';
}

.icon-HKEL::before {
  content: '\e97d';
}

.icon-HTP::before {
  content: '\e97e';
}

.icon-HUD::before {
  content: '\e97f';
}

.icon-HVLR::before {
  content: '\e980';
}

.icon-KEYL::before {
  content: '\e981';
}

.icon-KISI::before {
  content: '\e982';
}

.icon-KLI2::before {
  content: '\e983';
}

.icon-KLIM::before {
  content: '\e984';
}

.icon-KLST::before {
  content: '\e985';
}

.icon-LATR::before {
  content: '\e986';
}

.icon-LED::before {
  content: '\e987';
}

.icon-LED1::before {
  content: '\e988';
}

.icon-LED2::before {
  content: '\e989';
}

.icon-LED3::before {
  content: '\e98a';
}

.icon-LED4::before {
  content: '\e98b';
}

.icon-LED5::before {
  content: '\e98c';
}

.icon-LED6::before {
  content: '\e98d';
}

.icon-LED7::before {
  content: '\e98e';
}

.icon-LEDE::before {
  content: '\e98f';
}

.icon-LEDH::before {
  content: '\e990';
}

.icon-LEN1::before {
  content: '\e991';
}

.icon-LEN2::before {
  content: '\e992';
}

.icon-LEN3::before {
  content: '\e993';
}

.icon-LEN4::before {
  content: '\e994';
}

.icon-LEN5::before {
  content: '\e995';
}

.icon-LEN6::before {
  content: '\e996';
}

.icon-LEN7::before {
  content: '\e997';
}

.icon-LEN8::before {
  content: '\e998';
}

.icon-LUFT::before {
  content: '\e999';
}

.icon-LVER::before {
  content: '\e99a';
}

.icon-LVK::before {
  content: '\e99b';
}

.icon-MALV::before {
  content: '\e99c';
}

.icon-MONE::before {
  content: '\e99d';
}

.icon-MONM::before {
  content: '\e99e';
}

.icon-MP3A::before {
  content: '\e99f';
}

.icon-MSSA::before {
  content: '\e9a0';
}

.icon-NBSI::before {
  content: '\e9a1';
}

.icon-NEBE::before {
  content: '\e9a2';
}

.icon-NIV::before {
  content: '\e9a3';
}

.icon-PDC1::before {
  content: '\e9a4';
}

.icon-PDC2::before {
  content: '\e9a5';
}

.icon-PDC3::before {
  content: '\e9a6';
}

.icon-PDC4::before {
  content: '\e9a7';
}

.icon-PRO::before {
  content: '\e9a8';
}

.icon-RADI::before {
  content: '\e9a9';
}

.icon-RDK::before {
  content: '\e9aa';
}

.icon-REIF::before {
  content: '\e9ab';
}

.icon-REIN::before {
  content: '\e9ac';
}

.icon-RSE::before {
  content: '\e9ad';
}

.icon-SCR::before {
  content: '\e9ae';
}

.icon-SD::before {
  content: '\e9af';
}

.icon-SD1::before {
  content: '\e9b0';
}

.icon-SD2::before {
  content: '\e9b1';
}

.icon-SD4::before {
  content: '\e9b2';
}

.icon-SD5::before {
  content: '\e9b3';
}

.icon-SG::before {
  content: '\e9b4';
}

.icon-SG4::before {
  content: '\e9b5';
}

.icon-SG5::before {
  content: '\e9b6';
}

.icon-SG6::before {
  content: '\e9b7';
}

.icon-SG7::before {
  content: '\e9b8';
}

.icon-SG8::before {
  content: '\e9b9';
}

.icon-SIHE::before {
  content: '\e9ba';
}

.icon-SIHV::before {
  content: '\e9bb';
}

.icon-SIT::before {
  content: '\e9bc';
}

.icon-SITB::before {
  content: '\e9bd';
}

.icon-SITD::before {
  content: '\e9be';
}

.icon-SITE::before {
  content: '\e9bf';
}

.icon-SITH::before {
  content: '\e9c0';
}

.icon-SITV::before {
  content: '\e9c1';
}

.icon-SL::before {
  content: '\e9c2';
}

.icon-SONL::before {
  content: '\e9c3';
}

.icon-SORO::before {
  content: '\e9c4';
}

.icon-SPAA::before {
  content: '\e9c5';
}

.icon-SPFW::before {
  content: '\e9c6';
}

.icon-SPOI::before {
  content: '\e9c7';
}

.icon-SPSI::before {
  content: '\e9c8';
}

.icon-STAB::before {
  content: '\e9c9';
}

.icon-STHE::before {
  content: '\e9ca';
}

.icon-STUE::before {
  content: '\e9cb';
}

.icon-SWRA::before {
  content: '\e9cc';
}

.icon-SWRE::before {
  content: '\e9cd';
}

.icon-TEL1::before {
  content: '\e9ce';
}

.icon-TEL2::before {
  content: '\e9cf';
}

.icon-TEL3::before {
  content: '\e9d0';
}

.icon-TEL4::before {
  content: '\e9d1';
}

.icon-TEMP::before {
  content: '\e9d2';
}

.icon-TFAL::before {
  content: '\e9d3';
}

.icon-VVK::before {
  content: '\e9d4';
}

.icon-WDG::before {
  content: '\e9d5';
}

.icon-WFS::before {
  content: '\e9d6';
}

.icon-WIND::before {
  content: '\e9d7';
}

.icon-WIPA::before {
  content: '\e9d8';
}

.icon-XELI::before {
  content: '\e9d9';
}

.icon-ZUHE::before {
  content: '\e9da';
}

.icon-ZV::before {
  content: '\e9db';
}

.icon-checkmark::before {
  content: '\ea10';
}
