@keyframes cosHeartBeat {
  0% {
    transform: scale(1);
  }

  10% {
    transform: scale(1.1);
  }

  20% {
    transform: scale(1);
  }

  35% {
    transform: scale(1.1);
  }

  60% {
    transform: scale(1);
  }
}

.cosHeartBeat {
  animation-name: cosHeartBeat;
  animation-duration: 1.3s;
  animation-timing-function: ease-in-out;
}
