@use 'base/colors' as *;

@mixin draw-icon {
  color: white; // hide the text content
  overflow: hidden;
  position: relative;

  &::after {
    position: absolute;
    inset: 0;
    border: 0 solid $cos-blackish;
    border-top-width: 2px;
    border-left-width: 2px;
    content: '';
  }
}

// material: "expand_less", fontawesome: "angle_up" f106
button[aria-label='expand_less icon'] mat-icon {
  @include draw-icon;

  &::after {
    margin: 8px; // determines the size
    transform: translate(1px) rotate(45deg);
  }
}

// material: "expand_more" fontawesome: "angle_down" f107
button[aria-label='expand_more icon'] mat-icon {
  @include draw-icon;

  &::after {
    margin: 8px;
    transform: translate(1px) rotate(-135deg);
  }
}

// "check" icon on the ok button of the datepicker
// material: "done", fontawesome: "check" f00c
.mat-datepicker-content .actions button:last-child mat-icon {
  @include draw-icon;

  &::after {
    margin: 3px;
    width: 8px; // make it a rectangle (top border becomes shorter)
    transform: translate(4px, -2px) rotate(-135deg);
  }
}
